// 导入axios实例
import httpRequest from "@/request/index"

// 报警列表
export function alertList(param) {
    let pageSize = param.pageSize;
    let pageNum = param.pageNum;
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/alert/list?pageNum='+pageNum+'&pageSize='+pageSize,
        method: 'get',
        data: param,
    })
}

// 查询阈值设定列表
export function deviceList(param) {
    let pageSize = param.pageSize;
    let pageNum = param.pageNum;
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/device/list?pageNum='+pageNum+'&pageSize='+pageSize,
        method: 'get',
        data: param,
    })
}

// 新增阈值设定
export function saveSetting(data) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/device',
        method: 'post',
        data: data,
    })
}

// 修改阈值设定
export function editSetting(data) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/device',
        method: 'put',
        data: data,
    })
}

//根据ID查询阈值设定信息
export function findById(id) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/device/'+id,
        method: 'get',
    })
}

export function getListByTable(param) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/monitor/list?startTime='+param.startTime+'&endTime='+param.endTime,
        method: 'get',
    })
}

//监控数据查询
export function getListByParam(param) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/monitor/list2?deviceId='+param.id+'&startTime='+param.startTime+'&endTime='+param.endTime,
        method: 'get',
    })
}
//监控数据查询
export function exportData(dataType) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/system/monitor/export?dataType=0&type='+dataType,
        method: 'get',
    })
}

//监控数据查询
export function geyIconStatus() {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/mini/mqtt/listHomeStatus',
        method: 'get',
    })
}

export function openOne() {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/mini/mqtt/sent/clientTx/1/true',
        method: 'post',
    })
}
export function closeOne() {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/mini/mqtt/sent/clientTx/1/false',
        method: 'post',
    })
}
export function openFour() {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/mini/mqtt/sent/clientTx/4/true',
        method: 'post',
    })
}
export function closeFour() {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/mini/mqtt/sent/clientTx/4/false',
        method: 'post',
    })
}


