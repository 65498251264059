import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as Icons from '@element-plus/icons-vue'
import ZH_CN from 'element-plus/es/locale/lang/zh-cn';
import axios from 'axios'

const app = createApp(App).use(store).use(router)
app.use(ElementPlus, { locale: ZH_CN });
// 注册所有图标为全局组件
for (const name in Icons) {
    app.component(name, Icons[name]);
}

app.provide('$axios', axios)

app.mount('#app')
