<template>
  <div style="z-index:100;width:100%;height:100%;background-image: url('/static/images/sub_login.png');background-repeat: no-repeat;background-size: cover;">
    <div id="home" style="width:100%;height:100%;background-image: url('/static/images/home.png');background-repeat: no-repeat;background-size: cover;">
      <div style="position:absolute;left:47%;top:34%;z-index: 10;font-size: 26px;font-weight: 600;color:#FFFFFF">用 &nbsp;户 &nbsp;登 &nbsp;录</div>
      <div id="login" style="padding-top:70px;position:absolute;left:37%;top:33%;width:530px;height:260px;background-image: url('/static/images/login.png');background-repeat: no-repeat;background-size: cover;">
        <div style="margin:30px 70px;">
          <el-input v-model="userName" placeholder = "请输入账号" clearable :prefix-icon="User" >
            <template #prefix>
              <el-icon size="22px" color="#FFFFFF" ><User /></el-icon>
            </template>
          </el-input>
        </div>
        <div style="margin:30px 70px;">
          <el-input v-model="password" placeholder = "请输入密码" show-password >
            <template #prefix>
              <el-icon size="22px" color="#FFFFFF" ><Lock /></el-icon>
            </template>
          </el-input>
        </div>
        <div>
          <el-button type="primary" color="#2FDDE0" @click="login">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetUserInfo } from '@/apis/user'
 export default {
    name: 'Login',
     data () {
      return{
        userName:"",
        password:"",
      }
    },
    methods: {
      login() {
        const param = {
          username: this.userName,
          password: this.password,
        }
        apiGetUserInfo(param).then((res) => {
          if(res.code === 200){
            localStorage.setItem("token",res.token)
            this.$router.push({ name: 'home' });
          }else{
            this.$message({
              message: '账号或密码错误',
              type: 'error'
            })
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
}
</script>
<style scoped>
#home{
  width:100%;
  height:100%;
}
#login{

}
.el-input__wrapper{
  height:36px;
  background-color: #136F8D !important;
  border-radius: 5px !important;
  box-shadow: inset 5px 5px 10px rgba(135, 206, 235, 0.5),
  inset -5px -5px 10px rgba(135, 206, 235, 0.5),
  inset 5px -5px 10px rgba(135, 206, 235, 0.5),
  inset -5px 5px 10px rgba(135, 206, 235, 0.5);
}
.el-input__inner{
  color:#FFFFFF !important;
}

.el-input__inner::placeholder {
  color: #CCCCCC !important;
}

.el-button--primary{
  width:75%;
  color:#FFFFFF !important;
  border: 1px skyblue solid !important;
  background-color: #147893 !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
