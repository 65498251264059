<template>
  <div id="home" style="width:100%;height:100%;background-image: url('/static/images/home_notitle.png');background-repeat: no-repeat;background-size: cover;">
    <div style="z-index:10;width:100%;height:104px;position:absolute;top:0;margin:auto;background-image: url('/static/images/tile.png');background-repeat: no-repeat;background-size: cover;"></div>
    <div id="arrow" style="position:absolute;width:100%;height:100%; top:0;left:0;background-image: url('/static/images/back.png');background-repeat: no-repeat;background-size: cover;"></div>
    <div id="container"></div>
    <div style="position:absolute;padding-left:115px;padding-top:40px;bottom:0;left:12%;width:1200px;z-index:12;background-image: url('/static/images/buttomB.png');background-repeat: no-repeat;background-size: cover;">
      <div style="float: left;;cursor: pointer" @click="openFirstIcon">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconOne})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >分风直吹风门</div>
      </div>
      <div style="float: left;;cursor: pointer" >
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconTwo})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >除尘风机</div>
      </div>
      <div style="float: left;;cursor: pointer" >
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconThree})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >云雾降尘</div>
      </div>
      <div style="float: left;cursor: pointer" @click="openFourIcon">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconFour})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >冲水装置</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="detail">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconEight})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >数据监控</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="setting">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconSix})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >阈值设置</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="home">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconSeven})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >回首页</div>
      </div>
      <div style="float: left;;cursor: pointer" @click="warning">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconFive})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >报警设置</div>
      </div>
      <div style="float: left;cursor: pointer" @click="login">
        <div class="bottom-icon" :style="{ backgroundImage: `url(${iconNine})` }"></div>
        <div style="width:120px;height:20px;line-height: 20px;color:#FFFFFF;font-size: 14px;" >退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import PahoMQTT from 'paho-mqtt';
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { CSS2DRenderer,CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import {ElLoading} from "element-plus";
import { openOne,openFour,closeOne,closeFour,geyIconStatus } from '@/apis/data.js'

import { range, texture, mix, uv, color, positionLocal, timerLocal, SpriteNodeMaterial } from 'three/nodes';
import WebGPURenderer from 'three/addons/renderers/webgpu/WebGPURenderer.js'
import {CSS3DObject, CSS3DRenderer} from "three/addons";
import {AxesHelper} from "three";

 export default {
    name: 'HomeView',
    data () {
      this.scene = null;// 场景对象
      this.camera = null;// 透视摄像机
      this.container = null;// 透视摄像机
      this.orbitControls = null;// 渲染器
      this.labelRenderer = null;
      this.threeRenderer = null;
      this.render = null;// 渲染器
      this.particleSystem = null;
      return {
        client:null,
        isConnected:false,
        receivedMessages:[],
        titleList:[
          {name:'回风风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:-4.5,y:6.3,z:-0.3,arrowX:-4.5,arrowY:6.3,arrowZ:-0.3},
          {name:'进风风速',windSpeed:0,dust:0,type:'normal',distance:'long',x:-5.5,y:5.2,z:3.5,arrowX:-5.5,arrowY:5.8,arrowZ:2.8},

          {name:'分风风速',windSpeed:0,dust:0,type:'normal',distance:'long',x:-3.5,y:5,z:-3.7,arrowX:-3.5,arrowY:5.8,arrowZ:-3.2},
          {name:'左后风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:-0.5,y:5.8,z:-2.2,arrowX:-0.5,arrowY:5.8,arrowZ:-3.2},
          {name:'左中风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:2.5,y:5.8,z:-2.2,arrowX:2.5,arrowY:5.8,arrowZ:-3.2},
          {name:'左前风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:5.4,y:5.8,z:-2.2,arrowX:5.4,arrowY:5.8,arrowZ:-3.2},

          {name:'回风风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:-4.5,y:6.3,z:-0.3,arrowX:-4.5,arrowY:6.3,arrowZ:-0.3},
          {name:'直风风速',windSpeed:0,dust:0,type:'normal',distance:'long',x:-3.1,y:5.2,z:3.5,arrowX:-3.1,arrowY:5.8,arrowZ:2.8},
          {name:'右后风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:-0.5,y:5.8,z:1.8,arrowX:-0.5,arrowY:5.8,arrowZ:2.8},
          {name:'右中风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:2.5,y:5.8,z:1.8,arrowX:2.5,arrowY:5.8,arrowZ:2.8},
          {name:'右前风速粉尘',windSpeed:0,dust:0,type:'normal',distance:'long',x:5.4,y:5.8,z:1.8,arrowX:5.4,arrowY:5.8,arrowZ:2.8},
        ],
        tempTitleList:[
          {name:'左前风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:11,z:-12.5},
          {name:'左中风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:8.1,z:-13.2},
          {name:'左后风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:4.8,z:-14},
          {name:'分风风速',windSpeed:0,dust:0,only:true,type:'normal',distance:'long',x:0,y:1.2,z:-14.9},

          {name:'右前风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:9.6,z:7},
          {name:'右中风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:9.1,z:14.5},

          {name:'右后风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:8.5,z:24},
          {name:'直风风速',windSpeed:0,dust:0,only:true,type:'normal',distance:'long',x:0,y:2.5,z:26.6},
          {name:'进风风速',windSpeed:0,dust:0,only:true,type:'normal',distance:'long',x:0,y:-4.7,z:29.8},
          {name:'回风风速粉尘',windSpeed:0,dust:0,only:false,type:'normal',distance:'long',x:0,y:-14,z:33.8},
        ],
        particleCount:5000,
        firstAction:0,
        iconOne:'/static/icon/oneOff.png',
        iconTwo:'/static/icon/twoOff.png',
        iconThree:'/static/icon/threeOff.png',
        iconFour:'/static/icon/fourOff.png',
        iconFive:'/static/icon/fiveOff.png',
        iconSix:'/static/icon/sixOff.png',
        iconSeven:'/static/icon/sevenOff.png',
        iconEight:'/static/icon/eightOff.png',
        iconNine:'/static/icon/nineOff.png',
        oneStatus:false,
        fourStatus:false,
      }
    },
    methods: {
      login(){
        this.$router.push({ name: 'login' });
      },
      warning(){
        this.$router.push({ name: 'warning' });
      },
      setting(){
        this.$router.push({ name: 'setting' });
      },
      detail(){
        this.$router.push({ name: 'detail' });
      },
      home(){
        this.camera.position.set(-20,14,-9);//设置相机位置
        this.camera.lookAt(0, 4, 0)
        setTimeout(() => {
          this.rebackLabel();
          this.showLittleArrow();
        }, 200);
        this.orbitControls.target = new THREE.Vector3(0,4,0)
      },
      rebackLabel(){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            for (let k = 0; k < this.tempTitleList.length; k++) {
              if(this.scene.children[i].element.name === this.tempTitleList[k].name){
                this.scene.children[i].element.style.height ='120px'
                this.scene.children[i].position.set(this.tempTitleList[k].x,this.tempTitleList[k].y,this.tempTitleList[k].z)
              }
            }
          }
        }
      },
      clear(){
        document.body.removeChild(document.body.lastChild)
        document.body.removeChild(document.body.lastChild)
      },
      connect() {
        // 创建客户端实例
        this.client = new PahoMQTT.Client(
            'wss://mqtt.ilyixy.com:9002/ws', // MQTT服务器地址
            'clientId_' + Math.random().toString(16).substr(2, 8)
        );
        // 设置连接丢失的回调函数
        this.client.onConnectionLost = (responseObject) => {
          if (responseObject.errorCode !== 0) {
            console.log('onConnectionLost:', responseObject.errorMessage);
          }
        };
        // 设置消息到达的回调函数
        this.client.onMessageArrived = (message) => {
          console.log('onMessageArrived:', message.payloadString);
          this.message = message.payloadString;
        };
        // 连接到服务器
        this.client.connect({ userName:'admin',password:'Ly123456', onSuccess: this.onConnect, onFailure: this.onFailure });
      },
      onConnect() {
        console.log('Connected');
        this.client.subscribe('fc-home',(data) =>{
          console.log(data, 'recieved');
        });
        this.client.onMessageArrived = this.onMessageArrived;
      },
      onFailure(error) {
        console.log('Failed to connect:', error.errorMessage);
      },
      onMessageArrived(message){
        let data = JSON.parse(message.payloadString);
        let avgValue = 0;
        for (let i = 0; i < data.length; i++) {
          if(data[i].windSpeed >= 1.5 && data[i].windSpeed <= 2){
            if(data[i].name ==='分风风速' ){
              this.iconTwo = '/static/icon/twoNo.png';
            }else{
              this.iconTwo = '/static/icon/twoOff.png';
            }
            if(data[i].name ==='回风风速粉尘' ){
              this.iconThree = '/static/icon/threeNo.png';
            }else{
              this.iconThree = '/static/icon/threeOff.png';
            }
          }
          if(data[i].dust === ''){
            this.changeLabel(data[i].name,data[i].windSpeed,data[i].dust,true,data[i].hasAlert)
          }else{
            console.log(data[i])
            this.changeLabel(data[i].name,data[i].windSpeed,data[i].dust,false,data[i].hasAlert)
          }
          if(data[i].name === '中左风速粉尘' || data[i].name === '中右风速粉尘' || data[i].name === '后左风速粉尘' || data[i].name === '后右'
              || data[i].name === '前左风速粉尘' || data[i].name === '前右风速粉尘'){
            avgValue = avgValue + parseInt(data[i].dust);
          }
          if(data[i].name === '进风风速'){
            this.changeArrow(data[i].windSpeed)
          }
        }
        if( avgValue / 6 > 20 ){
          this.particleCount = 20000
        }
      },
      initScene() {
        this.scene = new THREE.Scene();
        let axesHelper = new THREE.AxesHelper(100);
        this.scene.add(axesHelper);
      },
      initLight(){
        const light = new THREE.AmbientLight(0xffffff, 0.8);//创建一个灯光
        this.scene.add(light)
        const plusLight = new THREE.DirectionalLight(0xffffff, 1.2);//创建一个环境
        plusLight.position.set(100,100,100);//设置光源的位置
        this.scene.add(plusLight); //将灯光添加到场景中
        const minLight = new THREE.DirectionalLight(0xffffff, 1);//创建一个环境
        minLight.position.set(-100,-100,-100);//设置光源的位置
        this.scene.add(minLight); //将灯光添加到场景中
      },
      initCamera(){
        //创建一个透视相机，视角为45度，宽高比为window窗口的宽高比，0.1为近面，10000为远面
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000);
        this.camera.position.set(-20,14,-9);//设置相机位置
        this.camera.lookAt(0,4,0);//设置相机位置
      },
      initName(name){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.textContent = name;
        labelDiv.style.backgroundImage = 'url(/static/images/plugin.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.height ='45px';
        labelDiv.style.textAlign ='center';
        labelDiv.style.lineHeight = '45px';
        labelDiv.style.width = '150px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initTitle(temp){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.style.pointerEvents = 'auto'
        if(temp.only){
          labelDiv.innerHTML =
              '<div style="font-size: 14px;">'+temp.name+'</div>' +
              '<div style="margin-top:30px;margin-left:0;line-height:60px;">风速 <span style="color:aqua;font-size: 16px;">'+temp.windSpeed+'</span>m/s</div>';
        }else{
          labelDiv.innerHTML =
              '<div style="font-size: 14px;">'+temp.name+'</div>' +
              '<div style="margin-top:20px;margin-left:0;line-height:40px;">风速 <span style="color:aqua;font-size: 16px;">'+temp.windSpeed+'</span>m/s</div>' +
              '<div style="margin-left:0;line-height:40px;">粉尘 <span style="color:aqua;font-size: 16px;">'+temp.dust+'</span>g/m³</div>';
        }
        if(!temp.only ){
          labelDiv.style.backgroundImage = 'url(/static/middleBlue.png)';
        }else{
          labelDiv.style.backgroundImage = 'url(/static/shortBlue.png)';
        }
        labelDiv.style.height ='120px';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.className = 'titleLabel';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.width = '140px';
        labelDiv.style.fontSize = '14px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = temp.name; // 设置文字颜色
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initArrow(name){
        const labelDiv = document.createElement('div');
        labelDiv.textContent = '';
        labelDiv.className = 'arrow';
        labelDiv.style.backgroundImage = 'url(/static/images/backArrow.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '0px 0px 0px 10px';
        labelDiv.style.height ='10px';
        labelDiv.style.width = '17px';
        labelDiv.style.fontSize = '5px'; // 设置文字大小
        labelDiv.style.lineHeight = '10px'; // 设置文字大小
        labelDiv.style.color = '#2DF6F5'; // 设置文字颜色
        labelDiv.name = 'smallArrow'; // 设置文字颜色
        return new CSS3DObject(labelDiv);
      },
      initLittleArrow(){
        for (let i = 0; i < this.titleList.length; i++) {
          const labelDiv = document.createElement('div');
          labelDiv.className = 'smallArrow';
          labelDiv.name = 'smallArrow';
          labelDiv.textContent = '';
          labelDiv.style.backgroundImage = 'url(/static/smallArrow.png)';
          labelDiv.style.backgroundSize = 'cover';
          labelDiv.style.height ='1.1px';
          labelDiv.style.width = '3px';
          labelDiv.style.fontSize = '2px'; // 设置文字大小
          labelDiv.style.lineHeight = '2px'; // 设置文字大小
          labelDiv.style.color = '#2DF6F5'; // 设置文字颜色
          let css3D = new CSS3DObject(labelDiv);
          css3D.position.set(this.titleList[i].arrowX,this.titleList[i].arrowY,this.titleList[i].arrowZ)
          if(this.titleList[i].name ==='回风风速粉尘'){
            css3D.rotateZ(Math.PI)
          }
          this.scene.add(css3D)
        }
      },
      hideLittleArrow(){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type === 'Object3D') {
            if(this.scene.children[i].element.name === 'smallArrow'){
              this.scene.children[i].visible = false
            }
          }
        }
        if(document.getElementById('arrow')){
          document.getElementById('arrow').style.display = 'none'
        }
      },
      showLittleArrow(){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type === 'Object3D') {
            if(this.scene.children[i].element.name === 'smallArrow'){
              this.scene.children[i].visible = true
            }
          }
        }
        if(document.getElementById('arrow')){
          document.getElementById('arrow').style.display = 'block'
        }
      },
      changeArrow(windSpeed){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            if(this.scene.children[i].element.name === "liuLiang") {
              this.scene.children[i].element.style.backgroundImage = 'url(/static/images/arrow.png)';
              // this.scene.children[i].element.textContent = '风量'+windSpeed+'m/s';
            }
          }
        }
      },
      initError(){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.style.backgroundImage = 'url(/static/images/error.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.height ='80px';
        labelDiv.style.width = '60px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initErrorTitle(content){ //基础材质
        const labelDiv = document.createElement('div');
        labelDiv.textContent = content;
        labelDiv.style.backgroundImage = 'url(/static/images/errorTitle.png)';
        labelDiv.style.backgroundSize = 'cover';
        labelDiv.style.padding = '10px 4px 4px 40px';
        labelDiv.style.height ='100px';
        labelDiv.style.width = '150px';
        labelDiv.style.fontSize = '18px'; // 设置文字大小
        labelDiv.style.color = '#efeff3'; // 设置文字颜色
        labelDiv.name = name;
        return new CSS2DObject(labelDiv);// 使用创建的 DIV 元素初始化一个 CSS2DObject 对象
      },
      initFire(){
        const textureLoader = new THREE.TextureLoader();
        const map = textureLoader.load( '/static/images/smoke1.png' );
        const lifeRange = range( .1, 1 );
        const offsetRange = range( new THREE.Vector3( - 2, 3, - 2 ), new THREE.Vector3( 2, 5, 2 ) );
        const timer = timerLocal( 0.2, 1/*100000*/ );
        const lifeTime = timer.mul( lifeRange ).mod( 1 );
        const scaleRange = range( 0.3, 2 );
        const rotateRange = range( 0.1, 4 );
        const life = lifeTime.div( lifeRange );
        const fakeLightEffect = positionLocal.y.oneMinus().max( 0.2 );
        const textureNode = texture( map, uv().rotateUV( timer.mul( rotateRange ) ) );
        const opacityNode = textureNode.a.mul( life.oneMinus() );
        const smokeColor = mix( color( '#222222' ), color( '#222222' ), positionLocal.y.mul( 3 ).clamp() );
        const smokeNodeMaterial = new SpriteNodeMaterial();
        smokeNodeMaterial.colorNode = mix( color( '#333333' ), smokeColor, life.mul( 2.5 ).min( 1 ) ).mul( fakeLightEffect );
        smokeNodeMaterial.opacityNode = opacityNode;
        smokeNodeMaterial.positionNode = offsetRange.mul( lifeTime );
        smokeNodeMaterial.scaleNode = scaleRange.mul( lifeTime.max( 0.3 ) );
        smokeNodeMaterial.depthWrite = false;
        smokeNodeMaterial.transparent = true;
        const smokeInstancedSprite = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), smokeNodeMaterial );
        smokeInstancedSprite.scale.setScalar( 5 );
        smokeInstancedSprite.isInstancedMesh = true;
        smokeInstancedSprite.count = 2000;
        smokeInstancedSprite.position.set( 10, -6, 0)
        this.scene.add( smokeInstancedSprite );
        const fireNodeMaterial = new SpriteNodeMaterial();
        fireNodeMaterial.colorNode = mix( color( '#222222' ), color( '#222222' ), life );
        fireNodeMaterial.positionNode = range( new THREE.Vector3( - 1, 1, - 1 ), new THREE.Vector3( 1, 2, 1 ) ).mul( lifeTime );
        fireNodeMaterial.scaleNode = smokeNodeMaterial.scaleNode;
        fireNodeMaterial.opacityNode = opacityNode;
        fireNodeMaterial.blending = THREE.AdditiveBlending;
        fireNodeMaterial.transparent = true;
        fireNodeMaterial.depthWrite = false;
        const fireInstancedSprite = new THREE.Mesh( new THREE.PlaneGeometry( 1, 1 ), fireNodeMaterial );
        fireInstancedSprite.scale.setScalar( 5 );
        fireInstancedSprite.isInstancedMesh = true;
        fireInstancedSprite.count = 100;
        fireInstancedSprite.position.y = - 100;
        fireInstancedSprite.renderOrder = 1;
        this.scene.add( fireInstancedSprite );
      },
      changeLabel(name, windSpeed, dust, only, hasAlert){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
           if(this.scene.children[i].element.name === name) {
             if(hasAlert === '0'){
               if(name === '进风风速' || name === '直风风速' || name === '分风风速'){
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/shortBlue.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 60px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>';
               }else if(name === '回风风速粉尘'){
                 console.log(name+":是否粉尘"+only+",风速"+windSpeed+",粉尘"+dust)
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/shortDustBlue.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 35px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>' +
                     '<div style="margin-left:30px;line-height: 30px;"">粉尘 <span style="color:aqua;font-size: 16px;">' + dust + '</span>g/m³</div>';

               }else{
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/middleBlue.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 35px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>' +
                     '<div style="margin-left:30px;line-height: 30px;"">粉尘 <span style="color:aqua;font-size: 16px;">' + dust + '</span>g/m³</div>';
               }
             }else if(hasAlert === '1'){
               if(name === '进风风速' || name === '直风风速' || name === '分风风速'){
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/shortRed.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 60px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>';
               }else if(name === '回风风速粉尘'){
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/shortDustRed.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 35px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>' +
                     '<div style="margin-left:30px;line-height: 30px;"">粉尘 <span style="color:aqua;font-size: 16px;">' + dust + '</span>g/m³</div>';
               }else{
                 this.scene.children[i].element.style.backgroundImage = 'url(/static/middleRed.png)'
                 this.scene.children[i].element.innerHTML =
                     '<div style="font-size: 14px;">' + name + '</div>' +
                     '<div style="margin-top:30px;margin-left:30px;line-height: 35px;">风速 <span style="color:aqua;font-size: 16px;">' + windSpeed + '</span>m/s</div>' +
                     '<div style="margin-left:30px;line-height: 30px;"">粉尘 <span style="color:aqua;font-size: 16px;">' + dust + '</span>g/m³</div>';
               }
             }
           }
          }
        }
      },
      changePosition(){
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].type ==='Object3D') {
            for (let k = 0; k < this.titleList.length; k++) {
              if(this.scene.children[i].element.name === this.titleList[k].name){
                if(this.titleList[k].name === '进风风速' || this.titleList[k].name === '直风风速' || this.titleList[k].name === '分风风速'){
                  this.scene.children[i].element.style.height ='375px'
                }else if(this.titleList[k].name === '回风风速粉尘'){
                  this.scene.children[i].element.style.height ='375px'
                }else{
                  this.scene.children[i].element.style.height ='500px'
                }
                this.scene.children[i].position.set(this.titleList[k].x,this.titleList[k].y,this.titleList[k].z)
              }
            }
          }
        }
      },
      initSprite(){
        const particles = new THREE.BufferGeometry();
        const positions = new Float32Array(this.particleCount * 3); // 每个粒子需要 x, y, z 坐标
        for (let i = 0; i < this.particleCount; i++) {
          positions[i * 3] = (Math.random()) * 50 -30;
          positions[i * 3 + 1] = (Math.random()) * 20 - 10 ;
          positions[i * 3 + 2] = (Math.random()) * 20 - 10;
        }
        particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        const material = new THREE.PointsMaterial({
          color: 0x000000,
          size: 4,
          transparent: true
        });
        this.particleSystem = new THREE.Points(particles, material);
        this.scene.add(this.particleSystem);
      },
      initObj(){
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/static/gltf/');
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.preload()
        const _this = this
        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        loader.load('/static/models/model2.glb', function ( gltf ) {
          const model = gltf.scene
          model.rotateY(Math.PI)
          for (let i = 0; i < _this.tempTitleList.length; i++) {
            let label = _this.initTitle(_this.tempTitleList[i])
            label.position.set(_this.tempTitleList[i].x,_this.tempTitleList[i].y,_this.tempTitleList[i].z);
            _this.scene.add(label);
          }
          let lLabel = _this.initArrow("liuLiang");
          lLabel.position.set(-12,6,3);
          lLabel.scale.set(0.1,0.1,0.1)
          _this.scene.add(lLabel);

          _this.initFire();
          model.position.set(-1,0,0)
          _this.scene.add(model)

        }, function ( xhr ) { // 加载过程中，输出加载进度信息
        }, function ( error ) { // 加载失败时，输出错误信息
          console.log( 'An error happened'+error );
        })
      },
      initRenderer(){//初始化渲染器
        this.renderer = new WebGPURenderer( { antialias: true } );
        this.container = document.getElementById("container");//获取容器
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);//设置渲染器的大小
        this.container.appendChild(this.renderer.domElement);//将渲染器添加到容器中
        this.renderer.setClearColor('#FFFFFF', 0);//设置背景颜色

        // 创建 CSS2D 渲染器
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        this.container.appendChild(this.labelRenderer.domElement);

        // 创建 CSS3D 渲染器
        this.threeRenderer = new CSS3DRenderer();
        this.threeRenderer.setSize(window.innerWidth, window.innerHeight);
        this.threeRenderer.domElement.style.position = 'absolute';
        this.threeRenderer.domElement.style.top = '0px';
        this.container.appendChild(this.threeRenderer.domElement);
      },
      initOrbitControls(){
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);//创建控制器
        this.orbitControls = new OrbitControls(this.camera, this.labelRenderer.domElement);//创建控制器
        this.orbitControls = new OrbitControls(this.camera, this.threeRenderer.domElement);//创建控制器
        this.orbitControls.enablePan = false;
        this.orbitControls.enableZoom = true;
        this.orbitControls.minDistance = 15
        this.orbitControls.maxDistance = 25
        this.orbitControls.minPolarAngle = Math.PI/3;
        this.orbitControls.maxPolarAngle = Math.PI/2;
        this.orbitControls.minAzimuthAngle = - Math.PI / 3 * 2;
        this.orbitControls.maxAzimuthAngle = - Math.PI /3;
        this.orbitControls.target = new THREE.Vector3(0,4,0)
        this.camera.position.set(-20,14,-9);//设置相机位置
        this.camera.lookAt(0,4,0);//设置相机位置
        let _this = this
        this.orbitControls.addEventListener('change', function () {
          if(_this.firstAction > 0){
            _this.hideLittleArrow()
            _this.changePosition();
          }else{
            _this.firstAction ++;
          }
        });
      },
      initAnimate() {
        requestAnimationFrame(this.initAnimate);
        if(this.orbitControls){
          this.orbitControls.update();
        }
        this.renderer.render(this.scene, this.camera);
        this.labelRenderer.render(this.scene, this.camera);
        this.threeRenderer.render(this.scene, this.camera);
      },
      checkToken(){
        let token = localStorage.getItem("token")
        if(token == null){
          this.$router.push({ name: 'login' });
        }
      },
      getStatus(){
        geyIconStatus().then((res) => {
          if(res.code === 200){
            for (let i = 0; i < res.data.length; i++) {
              if(res.data[i].pos === 1){
                if(res.data[i].status === 'ON'){
                  this.iconOne = '/static/icon/oneNo.png'
                  this.oneStatus = true
                }else{
                  this.iconOne = '/static/icon/oneOff.png'
                  this.oneStatus = false
                }
              }else if(res.data[i].pos === 4) {
                if (res.data[i].status === 'ON') {
                  this.iconFour = '/static/icon/fourNo.png'
                  this.fourStatus = true
                } else {
                  this.iconFour = '/static/icon/fourOff.png'
                  this.fourStatus = false
                }
              }
            }
          }
          console.log("value is"+this.iconOne)
        })
      },
      openFirstIcon(){
        if(this.oneStatus){
          closeOne().then((res) =>{
            setTimeout(() => {
              this.getStatus()
            },3000)
          })
        }else{
          openOne().then((res) =>{
            setTimeout(() => {
              this.getStatus()
            },3000)
          })
        }
      },
      openFourIcon(){
        if(this.fourStatus){
          closeFour().then((res) =>{
            setTimeout(() => {
              this.getStatus()
            },3000)
          })
        }else{
          openFour().then((res) =>{
            setTimeout(() => {
              this.getStatus()
            },3000)
          })
        }

      }
    },
    mounted() {
      this.checkToken();
      this.initScene();
      this.initLight();
      this.initCamera();
      this.initObj();
      this.initLittleArrow();
      this.initSprite();
      this.initRenderer();
      this.initOrbitControls();
      this.initAnimate();
      this.connect();
      this.getStatus();
    },
 }
</script>
<style scoped>
#home{
  width:100%;
  height:100%;
}
#container {
  width:100%;
  height:100%;
}
.bottom-icon{
  width:28px;
  height:28px;
  background-size: cover;
  margin-left: 36%
}
</style>
