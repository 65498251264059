<template>
  <div id="setting" style="width:100%;height:100%;background-image: url('/static/images/home.png');background-repeat: no-repeat;background-size: cover;">
    <div style="padding-top:8%;">
      <div style="float:left;margin-left:3%;line-height:40px;color:#FFFFFF;width:300px;height:40px;background-image: url('/static/images/warning.png');background-repeat: no-repeat;background-size: cover;">
        报警阈值设置
      </div>
      <div style="float:right;cursor:pointer;margin-right:40px;line-height:40px;color:#FFFFFF;width:120px;height:40px;background-image: url('/static/images/goback.png');background-repeat: no-repeat;background-size: cover;"  @click="goback"></div>
      <div style="float:right;cursor:pointer;margin-right:40px;line-height:40px;color:#FFFFFF;width:120px;height:40px;background-image: url('/static/images/add.png');background-repeat: no-repeat;background-size: cover;" @click="add"></div>
      <div style="width:100%;height:30px;"></div>
    </div>
    <div style="width:94%;margin-left:3%;margin-top:40px;">
      <el-table :data="tableData" border  style="width: 100%;"
                :header-cell-style="{backgroundColor:'#4E6F7E',color:'#FFFFFF',textAlign:'center'}"
                :cell-style="{backgroundColor:'#204863',color:'#FFFFFF',textAlign:'center'}">
           <el-table-column prop="name" label="位置名称" />
           <el-table-column prop="pos" label="点位" />
           <el-table-column prop="windSpeed" label="预警类型" />
           <el-table-column prop="windSpeedMax" label="预警最大值" />
           <el-table-column prop="windSpeedMin" label="预警最小值" />
           <el-table-column prop="dust" label="预警类型" />
           <el-table-column prop="dustMax" label="预警最大值" />
           <el-table-column prop="dustMin" label="预警最小值" />
          <el-table-column fixed="right" label="操作" min-width="120">
            <template #default="scope">
              <el-button link type="primary" size="small" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button link type="primary" size="small" @click="stopWarning">停止预警</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div style="float: right;margin-top:20px;">
        <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10]"
            :disabled="disabled"
            :background="background"
            layout="total, sizes, prev, pager, next, jumper"
            :total= total
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog :title="title"  v-model="open" width="700px" append-to-body>
      <el-form :model="form" label-width="auto" style="max-width: 600px">
        <el-form-item label="位置名称">
          <el-select v-model="form.name" placeholder="请选择位置名称">
            <el-option label="前左风速粉尘" value="1" />
            <el-option label="前右风速粉尘" value="2" />
            <el-option label="中左风速粉尘" value="3" />
            <el-option label="中右风速粉尘" value="4" />
            <el-option label="后左风速粉尘" value="5" />
            <el-option label="后右风速粉尘" value="6" />
            <el-option label="进风风速" value="7" />
            <el-option label="直风风速" value="8" />
            <el-option label="分风风速" value="9" />
            <el-option label="排风风速" value="10" />
            <el-option label="回风风速粉尘" value="11" />
          </el-select>
        </el-form-item>
        <el-form-item label="点位">
          <el-input v-model="form.pos" />
        </el-form-item>
        <el-form-item label="风速预警最大值">
          <el-input v-model="form.windSpeedMax" />
        </el-form-item>
        <el-form-item label="风速预警最小值">
          <el-input v-model="form.windSpeedMin" />
        </el-form-item>
        <el-form-item label="粉尘预警最大值">
          <el-input v-model="form.dustMax" />
        </el-form-item>
        <el-form-item label="粉尘预警最小值">
          <el-input v-model="form.dustMin" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deviceList, saveSetting } from "@/apis/data";
  export default {
    name: 'Table',
    data(){
      return{
        currentPage:1,
        pageSize:10,
        size: 'small',
        disabled:false,
        open:false,
        background:true,
        form:{
          name:'',
          pos:'',
          windSpeedMax:'',
          windSpeedMin:'',
          dustMax:'',
          dustMin:'',
        },
        tableData : [],
        total:0,
        param:{
          pageNum:1,
          pageSize:10
        },
        title: "",
      }
    },
    methods:{
      initData(){
        deviceList(this.param).then((res) => {
          if(res.code === 200){
            this.tableData = res.rows;
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].windSpeed = '风速'
              this.tableData[i].dust = '粉尘'
            }
            this.total = res.total;
          }else{
            this.$router.push({ path: '/login' });
          }
        }).catch((err) => {
          console.log(err)
        })
      },
      goback(){
        this.$router.push({path:'/'})
      },
      add(){
        this.title = '添加阈值'
        this.open = true
      },
      handleEdit(row){
        this.title = '编辑阈值'
        this.open = true
        this.form = row
      },
      stopWarning(){

      },
      submitForm(){
        saveSetting(this.form).then((res) =>{
          this.open = false
        }).catch((err) => {
          console.log(err)
        })
      },
      cancel(){
        this.form = {};
        this.open = false
      },
      handleSizeChange(value){
        this.param.pageSize = value
        this.initData();
      },
      handleCurrentChange(value){
        this.param.pageNum = value
        this.initData();
      }
    },
    created() {
      this.initData();
    }
  }
</script>
<style scoped lang="scss">
  .cell{
    font-size:11px;
    font-weight: 500;
  }
  .el-table .cell{
    padding:0 0 !important;
  }
  .el-table--border::before,
  .el-table--border::after {
    border-color: #FFFFFF !important;
  }
  .el-table td,
  .el-table th {
    border-color: #FFFFFF !important;
  }
  .el-pagination>.is-first{
    color:#FFFFFF !important;
  }
  .el-pagination__goto{
    color:#FFFFFF !important;
  }
  .el-table .el-table__cell{
    border-bottom: 1px solid #FFFFFF;
    z-index:100
  }

</style>
