<template>
  <div id="home" style="width:100%;height:100%;background-image: url('/static/images/home.png');background-repeat: no-repeat;background-size: cover;">
    <div style="padding-top:10%;">
      <div style="float:left;margin-left:3%;line-height:40px;color:#FFFFFF;width:300px;height:40px;background-image: url('/static/images/warning.png');background-repeat: no-repeat;background-size: cover;">
        报警记录
      </div>
      <div style="cursor:pointer;float:right;margin-right:40px;line-height:40px;color:#FFFFFF;width:120px;height:40px;background-image: url('/static/images/goback.png');background-repeat: no-repeat;background-size: cover;" @click="goback()"></div>
      <div style="width:100%;height:30px;"></div>
    </div>
    <div style="width:94%;margin-left:3%;margin-top:40px;">
      <el-table :data="tableData" border  style="width: 100%;"
                :header-cell-style="{backgroundColor:'#4E6F7E',color:'#FFFFFF',textAlign:'center'}"
                :cell-style="{backgroundColor:'#204863',color:'#FFFFFF',textAlign:'center'}">
        <el-table-column prop="name" label="报警点位" />
        <el-table-column prop="alertMsg" label="报警监控" />
        <el-table-column prop="updateTime" label="报警时间" />
      </el-table>
      <div style="float: right;margin-top:20px;">
        <el-pagination
            v-model:current-page="param.pageNum"
            v-model:page-size="param.pageSize"
            :page-sizes="[10]"
            :disabled="disabled"
            :background="background"
            layout="total, sizes, prev, pager, next, jumper"
            :total=total
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {alertList} from "@/apis/data";

export default {
  name: 'Table',
  data(){
    return{
      currentPage:1,
      pageSize:10,
      size: 'small',
      disabled:false,
      background:true,
      tableData : [],
      total:0,
      param:{
        pageNum:1,
        pageSize:10
      }
    }
  },
  methods:{
    initData(){
      alertList(this.param).then((res) => {
        if(res.code === 200){
          this.tableData = res.rows;
          this.total = res.total;
        }else{
          this.$router.push({ name: 'login' });
        }
      })
    },
    goback(){
      this.$router.push({ name: 'home' });
    },
    handleSizeChange(){
      this.initData();
    },
    handleCurrentChange(){
      this.initData();
    }
  },
  created() {
    this.initData();
  }
}
</script >
<style scoped>
.cell{
  font-size:11px;
  font-weight: 500;
}
.el-table .cell{
  padding:0 0 !important;
}
.el-scrollbar__wrap--hidden-default{
  background-color: #204863;
}
.el-table--border::before,
.el-table--border::after {
  border-color: #FFFFFF !important;
}
.el-table td,
.el-table th {
  border-color: #FFFFFF !important;
}
.el-pagination>.is-first{
  color:#FFFFFF !important;
}
.el-pagination__goto{
  color:#FFFFFF !important;
}
</style>
