<template>
  <div id="detail" style="width:100%;height:100%;background-image: url('/static/images/home.png');background-repeat: no-repeat;background-size: cover;">
    <div style="width: 100%;height:10%;padding-top:6%;display: flex">
      <div style="width:15%; height:100%;margin-left:10%;cursor: pointer">
        <div :class="['radioButton', additionalClass]" @click="changeValue('echarts')">图表</div>
        <div :class="['radioRightButton', minClass]" @click="changeValue('table')">表格</div>
      </div>
      <div class="block">
        <el-config-provider :locale="ZH_CN">
        <el-date-picker v-model="dateTimeRange"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        format="YYYY-MM-DD HH:mm"
                        date-format="YYYY/MM/DD ddd"
                        time-format="A hh:mm:ss"
                        unlink-panels/>
        </el-config-provider>
      </div>
      <div class="block" style="width:150px;margin-left: 30px;">
        <el-select v-model="selectValue" class="m-2" placeholder="请选择传感器">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="block">
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div class="block">
        <el-button type="primary">重置</el-button>
      </div>
      <div class="block">
        <el-button type="primary" @click="exportDay">日报表导出</el-button>
      </div>
      <div class="block">
        <el-button type="primary" @click="exportWeek">周报表导出</el-button>
      </div>
      <div class="block">
        <el-button type="primary" @click="exportMonth">月报表导出</el-button>
      </div>
        <div style="float:right;cursor:pointer;margin-right:20px;line-height:40px;color:#FFFFFF;width:120px;height:40px;background-image: url('/static/images/goback.png');background-repeat: no-repeat;background-size: cover;" @click="goback()"></div>
    </div>
    <div style="width:100%;height:70%">
      <div style="width:100%;height:100%" v-if="currentTab === 'echarts'"><Echarts :echartData="echartData"></Echarts></div>
      <div style="width:100%;height:100%" v-else ><Table :tableData="tableData"></Table></div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue';
import Echarts from '@/components/Echarts.vue';
import { getListByParam,getListByTable, exportData } from '@/apis/data'
export default {
  components: {
    Table,
    Echarts
  },
  data(){
    return {
      currentTab: 'echarts',
      dateTimeRange:[null,null],
      isAdditionalClassActive: true,
      selectValue:"",
      data:[],
      params:{
        id:1,
        startTime:null,
        endTime:null
      },
      options : [
        {
          value: '排风风速',
          label: '排风风速',
        },
        {
          value: '9',
          label: '回风风速粉尘',
        },
        {
          value: '8',
          label: '进风风速',
        },
        {
          value: '直射风速',
          label: '直射风速',
        },
        {
          value: '分风风速',
          label: '分风风速',
        },
        {
          value: '中左风速粉尘',
          label: '中左风速粉尘',
        },
        {
          value: '5',
          label: '中右风速粉尘',
        },
        {
          value: '前左风速粉尘',
          label: '前左风速粉尘',
        },
        {
          value: '4',
          label: '前右风速粉尘',
        },
        {
          value: '7',
          label: '后右风速粉尘',
        },
        {
          value: '6',
          label: '后左风速粉尘',
        }],
      echartData:[],
      tableData:[]
    }
  },
  computed: {
    additionalClass() {
      return this.isAdditionalClassActive ? 'additional-class' : '';
    },
    minClass(){
      return !this.isAdditionalClassActive ? 'additional-class' : '';
    },
  },
  methods: {
    search(){
      if(this.dateTimeRange[0] == null || this.dateTimeRange[1] == null){
        this.dateTimeRange[0] = new Date();
        this.dateTimeRange[1] =new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      }
      this.params.startTime = this.getDateTime(this.dateTimeRange[0])
      this.params.endTime = this.getDateTime(this.dateTimeRange[1])
      this.params.selectValue = this.selectValue
      this.tableData = []
      getListByTable(this.params).then((res) => {
        if(res.code !== 200){
          this.$router.push({ name: 'login' });
        }else{
          if(null != res.rows){
          for (let i = 0; i < res.rows.length; i++) {
            let temp = {}
            temp['createTime'] = res.rows[i].createTime
            if(res.rows[i].monitorList) {
              for (let j = 0; j < res.rows[i].monitorList.length; j++) {
                if (res.rows[i].monitorList[j].deviceId === 1){
                  temp['tLWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['tLDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 2){
                  temp['tRWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['tRDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 3){
                  temp['mLWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['mLDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 4){
                  temp['mRWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['mRDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 5){
                  temp['bLWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['bLDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 6){
                  temp['bRWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['bRDust'] = res.rows[i].monitorList[j].dust
                }else if (res.rows[i].monitorList[j].deviceId === 7){
                  temp['jWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                }else if (res.rows[i].monitorList[j].deviceId === 8){
                  temp['zWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                }else if (res.rows[i].monitorList[j].deviceId === 9){
                  temp['fWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                }else if (res.rows[i].monitorList[j].deviceId === 10){
                  temp['pWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                }else if (res.rows[i].monitorList[j].deviceId === 10){
                  temp['hWindSpeed'] = res.rows[i].monitorList[j].windSpeed
                  temp['hDust'] = res.rows[i].monitorList[j].dust
                }
              }
            }
            this.tableData.push(temp)
          }
        }
        }
      })
      getListByParam(this.params).then((res) => {
        this.echartData = res.data
      })
    },
    getDateTime(date){
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h=h < 10 ? ('0' + h) : h;
      let minute = date.getMinutes();
      minute = minute < 10 ? ('0' + minute) : minute;
      let second=date.getSeconds();
      second=second < 10 ? ('0' + second) : second;
      return y + '-' + m + '-' + d+' '+h+':'+minute;
    },
    goback(){
      this.$router.push({ path: '/' })
    },
    changeValue(val){
      this.currentTab = val
      this.isAdditionalClassActive = !this.isAdditionalClassActive;
      console.log(this.data)
    },
    exportDay(){
      exportData('0').then((res) => {
        console.log(res)
      })
    },
    exportWeek(){
      exportData('1').then((res) => {
        console.log(res)
      })
    },
    exportMonth(){
      exportData('2').then((res) => {
        console.log(res)
      })
    },
  },
  created() {
    this.search();
  }
}
</script>
<style scoped>
.el-radio-group{
  border: 1px skyblue solid;
}
.el-radio-button__inner{
  background-color: #2c3e50;
}
.radioButton{
  width:70px;
  height:35px;
  float: left;
  font-size: 14px;
  border:1px skyblue solid;
  color:#FFFFFF;
  line-height: 35px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: inset 5px 5px 10px rgba(135, 206, 235, 0.5),
            inset -5px -5px 10px rgba(135, 206, 235, 0.5);
}
.radioRightButton{
  width:70px;
  height:35px;
  float: left;
  font-size: 14px;
  border:1px skyblue solid;
  color:#FFFFFF;
  line-height: 35px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 5px 5px 10px rgba(135, 206, 235, 0.5),
  inset -5px -5px 10px rgba(135, 206, 235, 0.5);
}
.additional-class {
  background-color: #147893;
}
.el-range-editor.el-input__wrapper{
  background-color: #147893;
}

.el-range-editor .el-input__wrapper{
  border:1px skyblue solid;
}

.block{
  margin-top:5px;
  margin-left: 20px;
}
.el-button--primary{
  border: 1px skyblue solid !important;
  background-color: #147893 !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: inset 5px 5px 10px rgba(135, 206, 235, 0.5),
  inset -5px -5px 10px rgba(135, 206, 235, 0.5);
}
.el-input__wrapper{
  height:36px;
  background-color: #136F8D !important;
  border-radius: 5px !important;
  box-shadow: inset 5px 5px 10px rgba(135, 206, 235, 0.5),
  inset -5px -5px 10px rgba(135, 206, 235, 0.5),
  inset 5px -5px 10px rgba(135, 206, 235, 0.5),
  inset -5px 5px 10px rgba(135, 206, 235, 0.5);
}

.el-select-dropdown__item{
  background-color: #136F8D !important;
  color:#FFFFFF !important;
}

/* 日期选择框 */
input::placeholder {
  color: #FFFFFF !important;
  font-size: 14px;
}
.el-range-separator{
  color: #FFFFFF !important;
}

/**下拉框背景色**/
.el-select__wrapper{
  background-color: #147893 !important;
  font-size: 14px !important;
}
.el-select__placeholder{
  color:#FFFFFF !important;
  font-size: 12px !important;
}

</style>
