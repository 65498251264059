// 导入axios实例
import httpRequest from "@/request/index"

// 获取用户信息
export function apiGetUserInfo(param) {
    return httpRequest({
        url: 'https://fenchen.ilyixy.com/prod-api/login',
        method: 'post',
        data: param,
    })
}
